body {
    text-align: center;
    margin: auto;
}

.sprk-c-Masthead__site-links {
    border-right: none;
}

.sprk-c-Masthead__branding {
    a {
        text-decoration: none;
    }
}

.logo-title {
    font-size: 2rem;
    font-weight: bold;
    color: black;
    .logo-title-3 {
        color: $primary;
    }
}

.contents {
    display: contents;
}
.center {
    margin: auto;
}
.p-30 {
    padding: 30px;
}
.mt-30 { 
    margin-top: 30px !important;
}
.mt-10 { 
    margin-top: 10px !important;
}
.mt-20 { 
    margin-top: 20px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.ml-30 {
    margin-left: 30px !important;
}

.wide {
    max-width: 1000px !important;
}

.sprk-c-Button {
    background-color: $primary;
    border-color: $primary;
}
.sprk-c-Button--tertiary {
    background-color: white;
    border: 1px solid $primary;
}
.sprk-c-Button:hover, .sprk-c-Button:active, .sprk-c-Button:focus, .sprk-c-Button.sprk-c-Button--has-spinner[disabled]:hover, .sprk-c-Button.sprk-c-Button--has-spinner[disabled]:active, .sprk-c-Button.sprk-c-Button--has-spinner[disabled]:focus {
    background-color: $primary-light;
    border-color: $primary-light;
}
.sprk-c-Button--tertiary:hover, .sprk-c-Button--tertiary:active, .sprk-c-Button--tertiary:focus, .sprk-c-Button--tertiary.sprk-c-Button--tertiary--has-spinner[disabled]:hover, .sprk-c-Button--tertiary.sprk-c-Button--tertiary--has-spinner[disabled]:active, .sprk-c-Button--tertiary.sprk-c-Button--tertiary--has-spinner[disabled]:focus {
    background-color: white;
    border: 1px solid $primary;
}
.sprk-c-Masthead__link:hover {
    color: $primary;
    border-bottom: 0.125rem solid $primary;
}
.sprk-c-Masthead__link:hover, .sprk-c-Masthead__link:focus {
    color: $primary;
}

#button-copy {
    max-width: 200px;
    margin: auto;
}

.share-input-wrapper {
    margin: auto;
    .share-input {
        max-width: 300px;
        width: 300px;
    }
}

.game {
    .sprk-b-List {
        list-style: none;
    }
    .game-header {
        margin-top: 30px;
    }
    .image-card {
        margin: auto;
        margin-top: 30px;
    }
    .sprk-b-InputContainer {
        max-width: 100%;
    }
    .sprk-b-TextInput:focus {
        border-color: $primary;
        box-shadow: 0 0 0 1px $primary, 0 2px 8px #bababa;
    }
    .sprk-c-Card {
        max-width: 30rem;
    }

    #button-guess {
        margin-right: 20px;
    }

    #result-card {
        max-width: 1000px;
        margin-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        #button-home, #button-play-again {
            max-width: 200px;
            margin: auto;
            margin-bottom: 30px;
        }
        .sprk-b-Table th:last-child {
            background-color: $primary;
        }
        .sprk-b-Table td:last-child {
            font-weight: bold;
        }
    }

    .sprk-b-TableContainer {
        margin: auto;
        max-width: 700px;
        @media only screen and (max-width: 863px) {
            max-width: 100%;
        }
    }
}

.react-tabs__tab-list {
    border-bottom: none !important;
}
.react-tabs__tab--selected {
    border: none !important;
    border-bottom: 2px solid $primary !important;
}
.react-tabs__tab:hover {
    border-bottom: 2px solid $primary !important;
}
.react-tabs__tab:focus {
    box-shadow: 0 0 2px $primary !important;
}

.logout-link {
    @media only screen and (max-width: 863px) {
        float: left;
        padding: 16px;
    }
}

.rc-slider-track {
    background-color: $primary-light !important;
}
.rc-slider-handle {
    border: solid 2px $primary !important;
}
.rc-slider-handle:active {
    box-shadow: 0 0 5px $primary !important;
}
.rc-slider-dot, .rc-slider-dot-active {
    border: solid 2px $primary-light !important;
}
.rc-slider-disabled {
    background-color: unset !important;
}

.footer {
    float: right;
    margin-right: 30px;

}

@media only screen and (max-width: 863px) {
    .CookieConsent div:nth-child(2) {
        width: 100%;
        margin-right: 30px;
        button {
            width: 50%;
            margin-left: 30px !important;
            margin-right: 30px !important;
        }
    }
}

@media only screen and (max-width: 863px) {
    #button-guess, #button-show {
        margin-bottom: 30px;
    }
}

#actual {
    margin-top: 30px;
}


.category-card-container {
    position: relative;
    text-align: center;
}
.centered-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 1.3;
}
.category-card img {
    max-width: 300px;
    height: 200px;
    border-radius: 50px;
    opacity: 0.5;
    &.hover, &:hover {
        opacity: 0.8;
        transition: 0.5s ease;
        //height: 204px;
    }
}

.category-select-wrapper {
    margin: auto;
}
#category-modal footer .sprk-o-Stack__item:nth-child(3) {
    display: none;
}
.react-tabs__tab {
    border-bottom: 1px solid #ccc;
}


.score-table tr th:first-child {
    display: none;
}
.score-table tr td:first-child {
    display: none;
}
.score-table .our-score-row td:nth-child(2) {
    background: $primary;
    color: white;
}

.sprk-c-Dictionary__keyvaluepair {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
}
.sprk-c-Dictionary {
    margin-bottom: 30px;
}

#my-games-next {
    display: inline-block;
    text-align: right;
    margin: auto;
    margin-top: 30px;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

#my-games-prev {
    display: inline-block;
    text-align: right;
    margin: auto;
    margin-top: 30px;
    position: absolute;
    left: 20px;
    bottom: 20px;
}

.relative {
    position: relative;
}

.games-card {
    padding-bottom: 100px;
}
#instructions-modal footer .sprk-o-Stack__item:nth-child(3) {
    display: none;
}

#button-instructions {
    text-align: right;
    width: 100%;
}

#button-start {
    max-width: 200px;
    margin: auto;
    margin-bottom: 20px;
}

.correct {
    color: $green;
}
.incorrect {
    color: $red;
}